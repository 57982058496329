body {
    background-color: #2d2d2d;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../public/img/TicketsBackground\ -DieterK.jpg');
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    background-attachment: fixed;
}

#Nav {
    position: sticky;
    top: 0;
    display: flex;
    overflow: hidden;
    justify-content: center;
    height: 100px;
}

@media screen and (min-width: 768px) {
    #trapezoid {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        height: 0;
        border: 80px solid rgba(0, 0, 0, 0);
        border-top: 0 solid;
        border-bottom: 100px solid rgba(1, 1, 1, .4);
        -webkit-border-radius: 20px 20px 0 0;
        border-radius: 20px 20px 0 0;
        font: normal 100%/normal Arial, Helvetica, sans-serif;
        color: rgba(0, 0, 0, 0.7);
        -o-text-overflow: clip;
        text-overflow: clip;
        -webkit-transform: rotateX(180deg);
        transform: rotateX(180deg);
        margin-top: -53px;
        width: 700px;
        position: relative;
        transition: all 0.7s ease;
        padding-left: 10%;
        padding-top: 4%;
    }

    #trapezoid a {
        -webkit-transform: rotateX(180deg);
        transform: rotateX(180deg);
    }

    .navcontainer {
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        justify-content: space-between;
    }

    .navbar {
        position: sticky;
        top: 0;
        display: flex;
        overflow: hidden;
        justify-content: center;
        height: 100px;
    }

    .navbar a {
        float: left;
        font-size: 16px;
        color: white;
        text-align: center;
        padding: 14px 25px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 4px;
        transition: all 0.5s ease;
    }

    .navbar a:hover {
        background: #8CA6DB;
        color: black;
        border-radius: 5px;
    }
}





