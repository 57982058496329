@media only screen and (min-width: 550px) {
    .PhotoBy{
        position: fixed;
        bottom: 1vh;
        left: 1vw;
        color: rgba(255, 255, 255, .45);
        font-size: 15px;
    }
}

@media only screen and (max-width: 550px) {
    .PhotoBy{
        display: none;
    }
}