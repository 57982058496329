body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-default{
  border-radius: 20px;
  transition-duration: 0.4s;
  background-color: #4e4e4e;
  border: 2px solid #242424;
  color: rgb(255, 255, 255);
  width: 100%;
  height: 80%;
}

.btn-default:hover {
  color: rgb(255, 255, 255);
  background-color: #a60202;
}